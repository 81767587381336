import {formatDate} from '@common/lib'
import {transformHafasLocation} from './hafas'
import {transformTripStopTime} from './time'
import {transformTrack} from './track'
import {TRANSPORT_TYPES_ALL, TRANSPORT_TYPES_SHORT_DISTANCE, TRANSPORT_TYPES} from '@common/const'
import type {TransportType} from '@common/const'
import {createNewProductSelection, createNewSearchElaboration} from '@/helper'

// TODO:
// . tidy up!
export type SearchLocation = Omit<Dbi.Station, 'eva'> & {eva?: string}

export type SearchTransportType = {
    selection: 'all' | 'shortDistance' | 'custom'
    custom: TransportType[]
}

export type Stopover = {
    station: SearchLocation | null
    time: number | null
    products: SearchTransportType
}

export type SearchParams = {
    locations: {
        origin: SearchLocation
        destination: SearchLocation
    }
    time: {
        date: Date
        type: DbiApi.ConnectionRequest['tripType']
    }
    elaboration: {
        direct: boolean
        preferFast: boolean
        bikeCarriage: boolean
        changeTime: {
            type: 'variable' | 'fixed'
            value: number
        }
        products: SearchTransportType
        via: Stopover[]
    }
    context: DbiApi.ConnectionRequest['context']
}

export type PreviousSearch = Pick<SearchParams, 'locations' | 'elaboration'>

function transformLeaderboardStation({value, text, extId}: DbiApi.LeaderboardStation): SearchLocation {
    return {
        name: text,
        eva: extId,
        hafasId: value,
    }
}

function transformLeaderboardProducts(apiData: DbiApi.LeaderboardTrafficFilter): SearchTransportType {
    const products = createNewProductSelection()

    if (apiData.selected === 'shortDistanceOnly') {
        products.selection = 'shortDistance'
    }
    else {
        const custom = products.custom.filter(({hafas: {name}}) => apiData[name])

        if (custom.length !== TRANSPORT_TYPES.length) {
            products.selection = 'custom'
            products.custom = custom
        }
    }
    return products
}

export function transformSearchLeaderboard({
    jsonData,
}: DbiApi.SearchLeaderboardResponse['searchLeaderboard'][number]): PreviousSearch {
    const {trafficFilter, stops, start, destination}: DbiApi.LeaderboardSearch = JSON.parse(jsonData)

    const elaboration = createNewSearchElaboration()
    elaboration.products = transformLeaderboardProducts(trafficFilter)
    const via: Stopover[] = (elaboration.via = [])

    stops.forEach((stop) => {
        if (stop.active) {
            const {station, residenceTime, trafficFilter} = stop
            via.push({
                station: transformLeaderboardStation(station),
                time: residenceTime,
                products: transformLeaderboardProducts(trafficFilter),
            })
        }
    })

    return {
        locations: {
            origin: transformLeaderboardStation(start),
            destination: transformLeaderboardStation(destination),
        },
        elaboration,
    }
}

function getProducts({selection, custom}: SearchTransportType): number {
    switch (selection) {
        case 'all':
            return TRANSPORT_TYPES_ALL
        case 'shortDistance':
            return TRANSPORT_TYPES_SHORT_DISTANCE
        case 'custom':
            return custom.reduce((summedMasked, {bitmask}) => summedMasked + bitmask, 0)
    }
}

function setChangeTime(
    requestData: DbiApi.ConnectionRequest,
    {changeTime: {type, value}}: SearchParams['elaboration'],
): void {
    const field = type === 'fixed' ? 'minChangeTime' : 'changeTimePercent'
    requestData[field] = value
}

function createViaStationsArray({via}: Pick<SearchParams['elaboration'], 'via'>): string[] {
    return via.map(({station, time, products}) => {
        return `${(station as Dbi.Station).eva}|${time || ''}||${getProducts(products)}`
    })
}

export function createSearchRequest({
    locations: {origin, destination},
    time,
    elaboration,
    context,
}: SearchParams): DbiApi.ConnectionRequest {
    const requestData: DbiApi.ConnectionRequest = {
        customerLanguage: 'de',
        tripDate: formatDate(time.date),
        stationId: origin.hafasId as string,
        destinationId: destination.hafasId as string,
        rtMode: false,
        requestType: 'outward',
        tripType: time.type,
        products: getProducts(elaboration.products),
        context,
        via: createViaStationsArray(elaboration),
        economic: !elaboration.preferFast,
        bikeCarriage: elaboration.bikeCarriage,
        direct: elaboration.direct,
    }

    setChangeTime(requestData, elaboration)

    return requestData
}

const LINE_REGEX = /([0-9]*)$/

function getProductInfo({
    direction,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    product: {name, num, addName, line, catOut, catCode},
}: DbiApi.TripRoute): Dbi.TripSectionTransport['product'] {
    const train: Dbi.TripSectionTransport['product'] = {
        name: name,
        number: num,
        type: catOut,
        direction,
        catCode: parseInt(catCode) as Dbi.TripSectionTransport['product']['catCode'],
    }

    if (line) {
        train.name = `${catOut} ${line}`
        train.specificName = name
        train.line = line
    }
    else if (addName) {
        train.name = addName
        train.specificName = name
        const result = LINE_REGEX.exec(addName)
        if (result && result.length === 2) {
            train.line = result[1]
        }
    }

    return train
}

function getTrack({arrival, departure}: DbiApi.TripStop): Dbi.Track {
    const arrivalTrack = arrival.track
    const departureTrack = departure.track
    return transformTrack({
        soll: arrivalTrack.soll || departureTrack.soll,
        prog: arrivalTrack.prog || departureTrack.prog,
    })
}

function transformRouteStop(stop: DbiApi.TripStop): Dbi.TrainStop {
    const {arrival, departure} = stop
    const result = {
        station: transformHafasLocation(stop),
        track: getTrack(stop),
        canceled: stop.cancelled,
    } as Dbi.TrainStop

    if (arrival && arrival.dateSoll) {
        result.arrival = transformTripStopTime(arrival)
    }

    if (departure && departure.dateSoll) {
        result.departure = transformTripStopTime(departure)
    }

    return result
}

type Walk = Pick<DbiApi.TripRoute, 'destination' | 'origin'> & {
    walk: {
        distance: number
        duration: number
    }
}
function transformWalk({origin, destination, walk: {distance, duration}}: Walk): Dbi.TripSectionWalk {
    const section: Dbi.TripSectionWalk = {
        type: 'walk',
        walk: {distance, duration},
        reachable: true,
    }
    if (Object.keys(destination).length) {
        section.destination = (destination as DbiApi.TripPoint).name
    }
    if (Object.keys(origin).length) {
        section.origin = (origin as DbiApi.TripPoint).name
    }
    return section
}

function transformTransport(route: DbiApi.TripRoute): Dbi.TripSectionTransport {
    const {reachable, stops, walk, origin, destination, changeover} = route

    const mappedStops = stops
        .filter(({arrival, departure}) => arrival.dateSoll || departure.dateSoll)
        .map(transformRouteStop)
    const firstStop = mappedStops[0]
    const lastStop = mappedStops[mappedStops.length - 1]

    const himMessages = route.notes.de
        .filter(({key}) => key === '')
        .map((message) => ({text: message.value, key: message.key}))
    const infoMessages = route.notes.de
        .filter(({key}) => key !== '')
        .map((message) => ({text: message.value, key: message.key}))

    const section = {
        type: 'transport',
        product: getProductInfo(route),
        stops: mappedStops,
        // TODO
        // check if that is based on soll or prognosed times
        reachable,
        origin: (origin as DbiApi.TripPoint).name,
        destination: (destination as DbiApi.TripPoint).name,
        departure: {
            time: firstStop.departure as Dbi.TimeEntry,
            track: firstStop.track,
        },
        arrival: {
            time: lastStop.arrival as Dbi.TimeEntry,
            track: lastStop.track,
        },
        himMessages,
        infoMessages,
    } as Dbi.TripSectionTransport

    section.duration = calcDuration(section.arrival.time, section.departure.time)

    if (Object.keys(walk).length) {
        section.walk = walk as Dbi.Walk
    }
    if (changeover.minutesOnly) {
        section.changeoverTime = changeover.minutesOnly
    }
    if (section.reachable && section.walk && section.changeoverTime) {
        section.reachable = section.changeoverTime >= section.walk.duration
    }

    return section
}

function transformTripSections(routes: DbiApi.TripRoute[]): Dbi.TripSection[] {
    let previousSection: Dbi.TripSection | null = null
    return routes.map((route) => {
        const hasProduct = Object.keys(route.product).length
        const section = hasProduct ? transformTransport(route) : transformWalk(route as Walk)

        if (previousSection && section.walk && previousSection.type === 'walk' && !previousSection.walk.distance) {
            previousSection.walk = section.walk
            delete section.walk
        }

        previousSection = section
        return section
    })
}

function calcDuration(arrival: Dbi.TimeEntry, departure: Dbi.TimeEntry): number {
    return (arrival.time.valueOf() - departure.time.valueOf()) / 60000
}

function transformTrip(trip: DbiApi.Trip): Dbi.Connection {
    const {routes, tripId, alternative, departure, arrival} = trip
    const sections = transformTripSections(routes)
    const result = {
        tripId,
        origin: departure.name,
        destination: arrival.name,
        alternative,
        reachable: sections.every(({reachable}) => reachable),
        departure: transformTripStopTime(departure),
        arrival: transformTripStopTime(arrival),
        sections,
    } as Dbi.Connection

    result.duration = calcDuration(result.arrival, result.departure)

    const start = sections[0]
    if (start.type !== 'walk') {
        result.departureTrack = start.stops[0].track
    }

    const end = sections[sections.length - 1]
    if (end.type !== 'walk') {
        const lastRouteStops = end.stops
        result.arrivalTrack = lastRouteStops[lastRouteStops.length - 1].track
    }

    return result
}

export function transformConnectionResponse({
    context,
    scrollBackward,
    scrollForward,
    trips,
}: DbiApi.ConnectionResponse): {
    context?: string
    scrollBackward: string
    scrollForward: string
    connections: Dbi.Connection[]
} {
    return {
        context,
        scrollBackward,
        scrollForward,
        connections: trips.map(transformTrip),
    }
}
