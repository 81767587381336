import '@/sass/main.sass'

import log from '@common/lib/logger'

import Vue from 'vue'
import PortalVue from 'portal-vue'

import App from './App.vue'
import './unregisterServiceWorker'
import router from './router'
import store from './store'

import '@common/directives'
import '@common/filters'
import '@common/baseComponents'
import '@/baseComponents'

import DbiVuePlugin from '@/vue-plugins/dbiVuePlugin'

import '@/cordova'

Vue.config.productionTip = false

Vue.use(PortalVue)
Vue.use(DbiVuePlugin)

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app')

window.addEventListener('error', ({message, lineno, colno, filename}) => {
    log.error(`${message} (@ ${filename}?${lineno}:${colno})`)
})
