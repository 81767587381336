
import {FunctionalComponentOptions} from 'vue'

import {mergeData} from 'vue-functional-data-merge'

import {rootContext} from '@/store'

export type TopBarButtonOptions = {
    icon: string
    title?: string
    type?: string
    onClick: VoidFunction
}

export type TopBarOptions = {
    hasShadow: boolean
    buttons: Partial<Record<'left' | 'right', TopBarButtonOptions | null | false>>
}

const DEFAULT_RIGHT_BUTTON = {
    icon: 'menu',
    title: 'Menü',
    onClick: () => {
        rootContext.mutations.menuOpen(true)
    },
}

const component: FunctionalComponentOptions<TopBarOptions> = {
    name: 'TopBar',
    functional: true,
    props: {
        hasShadow: {
            type: Boolean,
        },
        buttons: {
            type: Object,
        },
    },
    render(createElement, {data, children, props: {hasShadow, buttons}}) {
        function renderButton({icon, title, type, onClick}: TopBarButtonOptions, position: 'left' | 'right') {
            return createElement('icon-button', {
                staticClass: `top-bar-button position-${position}`,
                props: {icon, title, type},
                on: {
                    click: (event: MouseEvent) => {
                        event.stopPropagation()
                        onClick()
                    },
                },
            })
        }

        const childElements = [createElement('h1', children)]

        if (buttons) {
            const {left, right} = buttons
            if (left) {
                childElements.unshift(renderButton(left, 'left'))
            }
            if (right) {
                childElements.push(renderButton(right, 'right'))
            }
        }
        if (!buttons || buttons.right === undefined) {
            // render default button (menu toggle)
            childElements.push(renderButton(DEFAULT_RIGHT_BUTTON, 'right'))
        }

        return createElement(
            'header',
            mergeData(data, {staticClass: 'top-bar', class: {'has-shadow': hasShadow}}),
            childElements,
        )
    },
}
export default component
