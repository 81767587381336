const doc = document

function _load(tag: 'link' | 'script') {
    return (url: string) => {
        return new Promise((resolve, reject) => {
            const element = document.createElement(tag)
            let parent = 'body'
            let attr = 'src'

            element.addEventListener('load', () => {
                resolve(url)
            })

            element.addEventListener('error', () => {
                reject(url)
            })

            // Need to set different attributes depending on tag type
            switch (tag) {
                case 'script':
                    ;(element as HTMLScriptElement).async = true
                    break
                case 'link':
                    element.type = 'text/css'
                    ;(element as HTMLLinkElement).rel = 'stylesheet'
                    attr = 'href'
                    parent = 'head'
            }

            // Inject into document to kick off loading
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            ;(element as any)[attr] = url
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            ;(doc as any)[parent].appendChild(element)
        })
    }
}

export async function loadInOrder(files: {type: 'script' | 'link'; url: string}[]): Promise<void> {
    /* eslint-disable-next-line no-async-promise-executor */
    return new Promise(async (resolve) => {
        for (let i = 0; i < files.length; i++) {
            const {type, url} = files[i]
            await _load(type)(url)
        }
        resolve()
    })
}

export const loadJs = _load('script')
export const loadCss = _load('link')
