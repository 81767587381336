export function transformTrack({prog, soll}: Pick<DbiApi.Track, 'prog' | 'soll'>): Dbi.Track {
    const track: Dbi.Track = {
        soll,
        changed: !!prog && prog !== soll,
    }
    if (prog) {
        track.prog = prog
    }
    return track
}
