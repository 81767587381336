
import type {FunctionalComponentOptions} from 'vue'
import {mergeData} from 'vue-functional-data-merge'

const component: FunctionalComponentOptions<{icon: string; title?: string}> = {
    name: 'SvgSprite',
    functional: true,
    props: {
        icon: {type: String, required: true},
        title: {type: String},
    },
    render(createElement, {data, props}) {
        const {icon, title} = props
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const iconFile = require(`@common/assets/img/svg-sprite/${icon}.svg`)
        const iconPath = Object.prototype.hasOwnProperty.call(iconFile, 'default') ? iconFile.default.url : iconFile.url

        const children = title ? [createElement('title', title)] : []

        children.push(
            createElement('use', {
                attrs: {
                    'xmlns:xlink': 'http://www.w3.org/1999/xlink',
                    'xlink:href': iconPath,
                },
            }),
        )

        const key = `svg-icon--${icon}`

        return createElement(
            'svg',
            mergeData(data, {
                key,
                staticClass: `svg-icon ${key}`,
                attrs: {
                    xmlns: 'http://www.w3.org/2000/svg',
                },
            }),
            children,
        )
    },
}
export default component
