import {transformStopTime} from './time'
import {transformTrack} from './track'
import {isAdditionalStop} from './transformHelper'
import {formatDate} from '@common/lib'

function getStopMarkings({
    firstStation,
    lastStation,
    canceled,
    additional,
}: Pick<Dbi.TrainDetailStop, 'firstStation' | 'lastStation' | 'canceled' | 'additional'>): string[] | undefined {
    if (canceled) {
        return ['Halt entfällt']
    }
    const result = []
    if (firstStation) {
        result.push('Startbahnhof')
    }
    if (lastStation) {
        result.push('Endbahnhof')
    }
    if (additional) {
        result.push('Zusatzhalt')
    }
    return result
}

export function getStationMarking(
    train: Pick<Dbi.Train, 'canceled' | 'arrival' | 'departure'>,
    details?: Pick<Dbi.TrainDetail, 'canceled'>,
): string | void {
    if (details?.canceled) {
        return 'Zug entfällt'
    }
    const {canceled, arrival, departure} = train
    if (canceled) {
        return 'Halt entfällt'
    }
    if (departure && !departure.canceled && (!arrival || arrival.canceled)) {
        return 'Startbahnhof'
    }
    if (arrival && !arrival.canceled && (!departure || departure.canceled)) {
        return 'Endbahnhof'
    }
}

function transformHimMessage({text, textIntern}: DbiApi.HimMessage): Dbi.HimMessage {
    return {
        text: !textIntern ? text : textIntern,
        key: '',
    }
}

function transformTpMessages(messages: DbiApi.Tp[]): Dbi.TpMessage[] {
    const msgMap: Record<string, {intern: string; extern: string; time?: Date}> = {}
    messages.forEach(({type, time, text}) => {
        const msg = msgMap[time] || {}
        msg[type] = text
        msgMap[time] = msg
    })
    return Object.entries(msgMap).map(([time, msg]) => {
        msg.time = new Date(time)
        return msg as Dbi.TpMessage
    })
}

export function transformTrainDetail(trainDetail: DbiApi.TrainDetailResponse, stationId: string): Dbi.TrainDetail {
    function transformTrainDetailStop(stop: DbiApi.TrainDetailStop): Dbi.TrainDetailStop {
        const result: Omit<Dbi.TrainDetailStop, 'changed' | 'canceled' | 'marking'> = {
            station: {
                eva: stop.stationEva,
                code: stop.stationCode,
                name: stop.stationName,
            },
            track: transformTrack(stop.track),
            firstStation: stop.firstStation,
            lastStation: stop.lastStation,
            currentStation: stationId.includes(stop.stationEva),
            additional: isAdditionalStop(stop),
        }

        transformStopTime(result, stop, 'arrival')
        transformStopTime(result, stop, 'departure')

        const {additional, arrival, departure} = result
        ;(result as Dbi.TrainDetailStop).canceled = ((!arrival || arrival?.canceled) &&
            (!departure || departure?.canceled)) as boolean
        ;(result as Dbi.TrainDetailStop).changed = additional || !!(arrival?.canceled || departure?.canceled)
        ;(result as Dbi.TrainDetailStop).markings = getStopMarkings(result as Dbi.TrainDetailStop)
        for (const type of ['firstStation', 'lastStation'] as const) {
            if (stop[type]) {
                result[type] = true
            }
        }

        return result as Dbi.TrainDetailStop
    }

    const route = trainDetail.route.map(transformTrainDetailStop)
    const result = {
        routeChanged: route.some(({changed}) => changed),
        canceled: route.every(({canceled}) => canceled),
        route,
        him: trainDetail.him.map(transformHimMessage),
        tp: transformTpMessages(trainDetail.tp),
    }
    Object.freeze(result)
    return result
}

type TrainDetailRequests = {
    TrainDetail: DbiApi.TrainDetailRequest
    WagonOrder: DbiApi.WagonOrderRequest
    ConnectingTrains?: DbiApi.ConnectingTrainsRequest
}
export function createTrainDetailRequests(train: Dbi.Train): TrainDetailRequests {
    const {
        number,
        station: {eva},
        arrival,
        departure,
    } = train
    const requests: TrainDetailRequests = {
        TrainDetail: {
            trainNumber: number,
            evaNumber: eva.toString(),
            departureDate: formatDate(departure?.soll),
            arrivalDate: formatDate(arrival?.soll),
            loadConnections: false,
        },
        WagonOrder: {
            action: 'get',
            trainNumber: number,
            timestamp: formatDate(arrival?.soll || departure?.soll) as string,
        },
    }
    // don't bother sending request if it is the Startbahnhof
    if (arrival) {
        requests.ConnectingTrains = {
            trainNumber: number,
            evaNumber: eva.toString(),
            sollArrivalDate: formatDate(arrival.soll) as string,
            progArrivalDate: formatDate(arrival.prog) as string,
        }
    }
    return requests
}
