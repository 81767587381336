export function createTypedStorage<DataDescription extends {[key: string]: unknown}>(
    storage: Storage,
    prefix: string,
): {
    get: typeof get
    getAndFill: typeof getAndFill
    getAll: typeof getAll
    set: typeof set
    setAll: typeof setAll
    remove: typeof remove
    removeAll: typeof removeAll
} {
    function get<Key extends keyof DataDescription, FallbackType = undefined>(
        key: Key,
        fallbackValue?: FallbackType,
    ): DataDescription[Key] | FallbackType
    function get<Key extends keyof DataDescription, FallbackType>(
        key: Key,
        fallbackValue: FallbackType,
    ): DataDescription[Key] | FallbackType {
        const value = storage.getItem(prefix + key)
        try {
            return value ? JSON.parse(value) : fallbackValue
        }
        catch (e) {
            console.error(e)
            return fallbackValue
        }
    }

    function getAndFill<Key extends keyof DataDescription, FallbackType>(
        key: Key,
        fallbackValue: FallbackType,
    ): DataDescription[Key] | FallbackType {
        const value = storage.getItem(prefix + key)
        try {
            if (value) {
                const parsed = JSON.parse(value)
                if (typeof parsed === 'object') {
                    return Object.assign(parsed, fallbackValue)
                }
                return parsed
            }
            return fallbackValue
        }
        catch (e) {
            console.error(e)
            return fallbackValue
        }
    }

    // TODO:
    // . needs better typing
    function getAll<Keys extends Array<keyof DataDescription>>(
        keys: Keys,
    ): Array<DataDescription[Keys[number]] | undefined> {
        return keys.map((key) => get(key))
    }

    function set<Key extends keyof DataDescription>(key: Key, value: DataDescription[Key]): void {
        if (value === undefined) {
            remove(key)
        }
        storage.setItem(prefix + key, JSON.stringify(value))
    }

    // TODO:
    // . needs better typing
    function setAll<Keys extends keyof DataDescription>(
        data: {
            [Key in Keys]: DataDescription[Key]
        },
    ): void {
        Object.entries(data).forEach(([key, value]) => {
            set(key, value as DataDescription[keyof DataDescription])
        })
    }

    function remove<Key extends keyof DataDescription>(key: Key): void {
        storage.removeItem(prefix + key)
    }

    function removeAll<Key extends keyof DataDescription>(keys: Key[]): void {
        keys.forEach(remove)
    }

    return {get, getAndFill, getAll, set, setAll, remove, removeAll}
}
