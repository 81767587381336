import {transformStopTime} from './time'
import {transformTrack} from './track'
import {isAdditionalStop} from './transformHelper'
import {setTrainShortcutInfos} from '@common/helper'

export function transformTrains(apiTrains: DbiApi.Train[]): Dbi.Train[] {
    return apiTrains.map((apiTrain) => {
        const {
            train: {
                name,
                number,
                line,
                type,
                startStationEva,
                startStationCode,
                startStation,
                endStationEva,
                endStationCode,
                endStation,
            },
            info: {id},
            track,
            station,
            notes,
        } = apiTrain
        const result: Omit<
            Dbi.Train,
            'time' | 'delayed' | 'timeVerified' | 'canceled' | 'canceledPartly' | 'specificName'
        > = {
            id,
            name,
            number,
            line,
            type,
            // be safe and clone object
            track: transformTrack(track),
            station: {
                eva: station.evaNumber,
                code: station.code,
                name: station.name,
            },
            origin: {
                eva: startStationEva,
                code: startStationCode,
                name: startStation,
            },
            destination: {
                eva: endStationEva,
                code: endStationCode,
                name: endStation,
            },
            hasNotification: !!notes.length,
            additional: isAdditionalStop(apiTrain),
        }
        if (line) {
            ;(result as Dbi.Train).specificName = `${type} ${number}`
        }
        transformStopTime(result, apiTrain, 'arrival')
        transformStopTime(result, apiTrain, 'departure')
        setTrainShortcutInfos(result as Dbi.Train)
        return result as Dbi.Train
    })
}
