
import {FunctionalComponentOptions} from 'vue'

import {mergeData} from 'vue-functional-data-merge'

import SvgSprite from '@common/components/baseComponents/SvgSprite.vue'
import NotificationIndicator, {NotificationOptions} from '@common/components/ui/NotificationIndicator.vue'

export type IconButtonOptions = {
    type: string
    icon: string
    title: string
    notification?: NotificationOptions | false | null
}

const component: FunctionalComponentOptions<IconButtonOptions> = {
    name: 'IconButton',
    functional: true,
    props: {
        type: {
            type: String,
            default: 'button',
        },
        icon: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        notification: {
            default: null,
        },
    },
    render(createElement, {data, props: {type, icon, title, notification = null}}) {
        const childElements = [createElement(SvgSprite, {props: {icon, title}})]

        if (notification) {
            childElements.push(
                createElement(NotificationIndicator, {
                    staticClass: 'icon-button-notification',
                    props: {...notification},
                }),
            )
        }

        return createElement('button', mergeData(data, {staticClass: 'icon-button', attrs: {type}}), childElements)
    },
}
export default component
