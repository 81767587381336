import Vue from 'vue'

import {isToday, padNumber} from '@common/lib'

// TODO:
// . make shareable
export function displayTime(date?: Date): string {
    if (!date || !(date instanceof Date)) {
        return ''
    }
    const hours = padNumber(date.getHours())
    const minutes = padNumber(date.getMinutes())
    return `${hours}:${minutes}`
}

const dateFormatter = new Intl.DateTimeFormat('de-DE', {
    weekday: 'short',
    month: 'numeric',
    day: 'numeric',
})
export function displayDay(date: Date): string {
    return isToday(date) ? 'Heute' : dateFormatter.format(date)
}

const weekdayFormatter = new Intl.DateTimeFormat('de-DE', {weekday: 'short'})
export type DisplayDateOptions = {
    showWeekday?: boolean
    noYear?: boolean
}
export function displayDate(date?: Date, options: DisplayDateOptions = {}): string {
    if (!date || !(date instanceof Date)) {
        return ''
    }

    const {showWeekday, noYear} = options

    const day = padNumber(date.getDate())
    const month = padNumber(date.getMonth() + 1)

    let dayStr = `${day}.${month}.`

    if (showWeekday) {
        const weekDay = weekdayFormatter.format(date)
        dayStr = `${weekDay} ${dayStr}`
    }

    if (!noYear) {
        const year = padNumber(date.getFullYear())
        dayStr = `${dayStr}.${year}`
    }

    return dayStr
}

export function displayDateTime(date?: Date, noYear?: boolean): string {
    if (!date || !(date instanceof Date)) {
        return ''
    }
    const day = padNumber(date.getDate())
    const month = padNumber(date.getMonth() + 1)
    const year = padNumber(date.getFullYear())
    const hours = padNumber(date.getHours())
    const minutes = padNumber(date.getMinutes())

    if (noYear) {
        return `${day}.${month}. / ${hours}:${minutes}`
    }
    return `${day}.${month}.${year} / ${hours}:${minutes}`
}

export function renderNameNumber({name, number}: {name: string; number: number}): string {
    return `${name} (#${number})`
}

Vue.filter('displayTime', displayTime)
Vue.filter('displayDay', displayDay)
Vue.filter('displayDate', displayDate)
Vue.filter('displayDateTime', displayDateTime)
