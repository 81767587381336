import {parse} from 'qs'
import {typedStorage} from '@/helper'

const INITIAL_QUERY = Object.freeze(parse(location.search, {ignoreQueryPrefix: true}))

function getQueryField(field: 'appVersion' | 'appBuild'): string | undefined {
    const value = INITIAL_QUERY[field] as string | undefined
    if (value) {
        typedStorage.set(field, value)
    }
    return value || typedStorage.get(field)
}

const APP_VERSION = getQueryField('appVersion')
const APP_BUILD = getQueryField('appBuild')

export const ENV = {
    IS_APP: !!(APP_VERSION || APP_BUILD),
    APP_VERSION,
    APP_BUILD,
    PWA_VERSION: process.env.VUE_APP_VERSION as string,
    PWA_BUILD: process.env.VUE_APP_BUILD as string,
    LOGIN_ALLOW_NON_SSO: !!process.env.VUE_APP_LOGIN_ALLOW_NON_SSO,
} as const
