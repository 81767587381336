
import type {FunctionalComponentOptions, VNode} from 'vue'
import {mergeData} from 'vue-functional-data-merge'

type DbiLoaderProps = {
    loading: boolean
    hint?: string
    replaceChildren: boolean
}

type DisplayStyle = {display?: string}

const component: FunctionalComponentOptions<DbiLoaderProps> = {
    name: 'DbiLoader',
    functional: true,
    props: {
        loading: {
            type: Boolean,
            required: true,
        },
        hint: {
            type: String,
        },
        replaceChildren: {
            type: Boolean,
            default: false,
        },
    },
    render(createElement, {data, props, children}) {
        const {loading, hint, replaceChildren} = props

        const loaderEles = [
            createElement('svg-sprite', {
                props: {icon: 'refresh'},
                staticClass: 'spin',
            }),
        ]

        if (hint) {
            loaderEles.push(createElement('p', hint))
        }

        function renderHideLoader(): VNode {
            const style: DisplayStyle = {}
            const childStyles: DisplayStyle = {}
            if (!loading) {
                style.display = 'none'
            }
            else {
                childStyles.display = 'none'
            }

            return createElement('div', mergeData(data, {staticClass: 'dbi-loader-wrapper'}), [
                createElement('div', {staticClass: 'dbi-loader', style}, loaderEles),
                createElement(
                    'div',
                    {
                        staticClass: 'dbi-loader-children',
                        style: childStyles,
                    },
                    children,
                ),
            ])
        }

        function renderReplaceLoader(): VNode | VNode[] {
            return loading ? createElement('div', {staticClass: 'dbi-loader'}, loaderEles) : children
        }

        return replaceChildren ? renderReplaceLoader() : renderHideLoader()
    },
}

export default component
