



















import {Vue, Ref, Model, Component, Prop} from 'vue-property-decorator'

import {Subscription} from '@/decorators'
import {transformHafasLocationResponse} from '@/transforms'

import DbiSuggestInput, {DbiSuggestOptions} from '@/components/form/DbiSuggestInput.vue'

export type StationSelectOptions = {
    id?: string
    hint?: string
    placeholder?: string
    hideLabel?: boolean
    type?: DbiApi.HafasLocationRequest['type']
    clearOnOpen?: boolean
    forceSelection?: boolean
    instantFocus?: boolean
    resetable?: boolean
    products?: number
}

const OPTION_DEFAULTS = {
    id: 'station',
    itemTitle: 'name',
    type: 'ALL',
    clearOnOpen: true,
    forceSelection: false,
    instantFocus: false,
    name: 'station-select',
}

@Component({
    name: 'StationSelect',
    components: {
        DbiSuggestInput,
    },
})
export default class StationSelect extends Vue {
    @Model('input')
    private readonly value!: Dbi.Station | null

    @Prop({type: Object})
    private readonly options!: StationSelectOptions

    @Ref()
    private readonly select!: DbiSuggestInput<Dbi.Station>

    get innerOptions(): StationSelectOptions & DbiSuggestOptions<Dbi.Station> {
        const opts = Object.assign({}, OPTION_DEFAULTS, this.options) as StationSelectOptions &
            DbiSuggestOptions<Dbi.Station>
        if (this.options.resetable) {
            opts.reset = {
                icon: 'home',
                title: 'zurücksetzen',
                value: this.$dbiStore.state.homeStation as Dbi.Station,
            }
        }
        return opts
    }

    get station(): Dbi.Station | null {
        return this.value
    }

    set station(station: Dbi.Station | null) {
        this.$emit('input', station)
    }

    stationSuggestions: Dbi.Station[] = []

    mounted(): void {
        if (this.innerOptions.instantFocus) {
            this.select.focus()
        }
    }

    close(): void {
        this.stationSuggestions = []
        this.$emit('close')
    }

    onStationInput(input: string): void {
        this.$queue.send('HafasLocation', {
            input,
            type: this.innerOptions.type as DbiApi.HafasLocationRequest['type'],
        })
    }

    // TODO:
    // . move products filter to BE (send with the request to HAFAS)
    @Subscription('HafasLocation')
    gotResponse(response: DbiApi.HafasLocationResponse): void {
        const locations = transformHafasLocationResponse(response)
        const {products: filterProducts} = this.innerOptions
        this.stationSuggestions = filterProducts
            ? locations.filter(({products}) => products && filterProducts & products)
            : locations
    }

    public focus(): void {
        this.select.focus()
    }
}
