import Vue from 'vue'
import log from 'loglevel'

const MAX_LOG_SIZE = 15

type LogMessage = {
    time: Date
    type: string
    payload: unknown & {
        shortMessage?: string
        show?: boolean
    }
}
export const actionLog: Array<LogMessage> = Vue.observable([])

export function logAction(type: string, payload: LogMessage['payload']): void {
    actionLog.push({
        time: new Date(),
        type,
        payload,
    })

    if (actionLog.length > MAX_LOG_SIZE) {
        const cutLength = actionLog.length - MAX_LOG_SIZE
        actionLog.splice(0, cutLength)
    }
}

export const logMessages: unknown[] = Vue.observable([])

const originalFactory = log.methodFactory
log.methodFactory = (methodName, logLevel, loggerName) => {
    const rawMethod = originalFactory(methodName, logLevel, loggerName)

    return (message) => {
        logAction(methodName, message)
        rawMethod(message)
    }
}
log.setLevel(log.getLevel())
log.enableAll()

export default log
