import {isDelayed} from '@/helper'

function setProgDate(timeEntry: Dbi.TimeEntry, prog: string): Dbi.TimeEntry {
    if (prog) {
        const progDate = new Date(prog)
        timeEntry.prog = progDate
        timeEntry.time = progDate
        timeEntry.delayed = isDelayed(timeEntry)
    }

    return timeEntry
}

export function transformTimeEntry(
    apiTime: Optional<
        Pick<DbiApi.TrainStopTime, 'soll' | 'prog' | 'diff' | 'sign' | 'state' | 'status'>,
        'state' | 'status'
    >,
): Dbi.TimeEntry {
    const {soll, prog, diff, sign, state, status} = apiTime
    const sollDate = new Date(soll)
    const canceled = status === 'Ausf' || status === 'UmlAusf'
    const timeEntry: Dbi.TimeEntry = {
        soll: sollDate,
        time: sollDate,
        diff,
        sign,
        diffStr: `${sign}${diff}`,
        verified: canceled || state === 'Ist',
        canceled,
    }

    setProgDate(timeEntry, prog)

    return timeEntry
}

export function transformStopTime<
    Target extends {arrival?: Dbi.TimeEntry; departure?: Dbi.TimeEntry},
    Source extends Pick<DbiApi.Train, 'arrival' | 'departure'>
>(target: Target, apiTrain: Source, field: 'arrival' | 'departure'): void {
    if (!Object.keys(apiTrain[field]).length) {
        return
    }

    target[field] = transformTimeEntry(apiTrain[field] as DbiApi.TrainStopTime)
}

export function transformTripStopTime({dateSoll, dateProg, diff, sign, state}: DbiApi.TripStopTime): Dbi.TimeEntry {
    const soll = new Date(dateSoll)
    const timeEntry = {
        soll,
        time: soll,
        diff,
        sign,
        diffStr: `${sign}${diff}`,
        verified: state === 'MANUAL',
    }

    setProgDate(timeEntry, dateProg)

    return timeEntry
}
