async function unregisterServiceWorker(): Promise<void> {
    if ('serviceWorker' in navigator) {
        try {
            const registrations = await navigator.serviceWorker.getRegistrations()
            for (const registration of registrations) {
                registration.unregister()
            }
        }
        catch (error) {
            console.log('Service Worker registration failed: ', error)
        }
    }
}
unregisterServiceWorker()
