import md5 from 'md5'

export function createCertificateHash(
    {name, specificName, arrival, departure}: Pick<Dbi.Train, 'name' | 'specificName' | 'arrival' | 'departure'>,
    {eva}: Pick<Dbi.Station, 'eva'>,
    variant: Dbi.CertificateVariant,
): string {
    let train = specificName || name
    train += arrival?.soll.valueOf() || ''
    train += departure?.soll.valueOf() || ''
    const h = md5(`${train}${eva}${variant}`).substr(0, 16)
    return parseInt(h, 16).toString(36).substring(0, 8).toUpperCase()
}
