
import {FunctionalComponentOptions} from 'vue'

import {mergeData} from 'vue-functional-data-merge'
import type {TopBarOptions, TopBarButtonOptions} from '@/components/baseComponents/TopBar.vue'

const component: FunctionalComponentOptions<TopBarOptions> = {
    name: 'DbiLayerTopBar',
    functional: true,
    props: {
        hasShadow: {
            type: Boolean,
        },
        buttons: {
            type: Object,
        },
    },
    render(createElement, {data, children, props: {hasShadow, buttons}}) {
        function renderButton({icon, title, type, onClick}: TopBarButtonOptions, position: 'left' | 'right') {
            return createElement('icon-button', {
                staticClass: `top-bar-button position-${position}`,
                props: {icon, title, type},
                on: {click: onClick},
            })
        }

        const childElements = [createElement('h1', children)]

        if (buttons) {
            const {left, right} = buttons
            if (left) {
                childElements.unshift(renderButton(left, 'left'))
            }
            if (right) {
                childElements.push(renderButton(right, 'right'))
            }
        }

        return createElement(
            'header',
            mergeData(data, {staticClass: 'layer-top-bar', class: {'has-shadow': hasShadow}}),
            childElements,
        )
    },
}
export default component
