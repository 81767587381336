import type {StompConfig} from '@stomp/stompjs'

export const STOMP_CLIENT_SETTINGS: StompConfig = {
    brokerURL: process.env.VUE_APP_RMQ_URL as string,
    connectHeaders: {
        host: process.env.VUE_APP_RMQ_VHOST as string,
    },
    heartbeatIncoming: 30000,
    heartbeatOutgoing: 30000,
} as const

export const URLS = {
    DELAY_CERTIFICATES: process.env.VUE_APP_DELAY_CERTIFICATE_URL as string,
    PRIVACY: process.env.VUE_APP_PIA_PRIVACY_URL as string,
    STATION_MAP: 'https://maps.reisenden.info/map/index.html',
} as const
Object.freeze(URLS)

export const enum HALT_OPTIONS_VALUES {
    All,
    Arrival,
    Departure,
}
export const HALT_OPTIONS = [
    {value: HALT_OPTIONS_VALUES.All, title: 'Alle'},
    {value: HALT_OPTIONS_VALUES.Arrival, title: 'Ankunft', filter: ({arrival}: Pick<Dbi.Train, 'arrival'>) => arrival},
    {
        value: HALT_OPTIONS_VALUES.Departure,
        title: 'Abfahrt',
        filter: ({departure}: Pick<Dbi.Train, 'departure'>) => departure,
    },
] as const

export const enum TRAVELSTATE_OPTIONS_VALUES {
    All,
    Driving,
    Canceled,
}
export const TRAVELSTATE_OPTIONS = [
    {value: TRAVELSTATE_OPTIONS_VALUES.All, title: 'Alle'},
    {
        value: TRAVELSTATE_OPTIONS_VALUES.Driving,
        title: 'Fahrende Züge',
        filter: ({canceled}: Pick<Dbi.Train, 'canceled'>) => !canceled,
    },
    {
        value: TRAVELSTATE_OPTIONS_VALUES.Canceled,
        title: 'Ausfälle',
        filter: ({canceled, canceledPartly}: Pick<Dbi.Train, 'canceled' | 'canceledPartly'>) =>
            canceled || canceledPartly,
    },
] as const
