import Vue from 'vue'
import type {PluginObject} from 'vue/types/plugin'

import {ENV} from '@/env'
import {Queue} from '@/api'
import {rootContext} from '@/store'
import log from '@common/lib/logger'

export type TopBarButtonSettings = {
    icon: string
    title?: string
    style?: Record<string, string>
    onClick?: (event: MouseEvent) => void
}

export type DbiVuePlugin = {
    $env: typeof ENV
    $queue: typeof Queue['prototype']
    $dbiStore: typeof rootContext
    $log: typeof log
}

export const settings = Vue.observable({
    topBarButton: undefined as TopBarButtonSettings | undefined,
})

const DbiVuePlugin: PluginObject<void> = {
    install(Vue) {
        Vue.prototype.$env = ENV
        Vue.prototype.$queue = Queue.getInstance()
        Vue.prototype.$dbiStore = rootContext
        Vue.prototype.$log = log
    },
}

export default DbiVuePlugin
