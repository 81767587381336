






import {Component, Vue} from 'vue-property-decorator'

@Component({
    name: 'DbiToast',
})
export default class DbiToast extends Vue {}
