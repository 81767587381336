import {pad} from './strings'

export function timePad(value: string | number): string {
    return pad(value, 2, '0')
}

const TIMEZONE_OFFSET = (() => {
    const offset = new Date().getTimezoneOffset() * -1
    const hours = Math.floor(offset / 60)
    const minutes = offset - hours * 60
    return `${timePad(hours)}:${timePad(minutes)}`
})()

/**
 * formats given date into ISO-Format needed by the backend
 * e.g. 2020-07-09T18:42:00+02:00
 * @param date
 */
export function formatDate<T extends Date | undefined>(date: T): T extends Date ? string : undefined {
    if (!date) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return
    }
    const year = timePad(date.getFullYear())
    const month = timePad(date.getMonth() + 1)
    const day = timePad(date.getDate())
    const hours = timePad(date.getHours())
    const minutes = timePad(date.getMinutes())
    const seconds = timePad(date.getSeconds())
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}+${TIMEZONE_OFFSET}`
}
