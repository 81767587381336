import {Client, Frame, StompSubscription} from '@stomp/stompjs'

import log from '@common/lib/logger'
import {STOMP_CLIENT_SETTINGS, TASKS, INCOMING_CHANNELS} from '@/const'
import {removeFromArray} from '@common/lib'

// taken from old source. Not sure if it is changing for certain Endpoints.
const QUEUE_PREFIX = '/amq/queue/'

type Requests = DbiApi.Requests
type Responses = DbiApi.Responses
type ResponseError = DbiApi.ResponseError

type RequestType = keyof Requests
export type ResponseType = keyof Responses

type CallbackFunction<Type extends ResponseType> = (data: Responses[Type]) => void

type ChannelCallbacks = {
    [Type in ResponseType]?: MaybeArray<CallbackFunction<Type>>
}

type Subscriptions = {
    [Type in ResponseType]?: {
        stompSub: StompSubscription
        callbacks: Array<CallbackFunction<Type>>
    }
}

export class Queue {
    private static instance: Queue | null = null

    private client: Client
    private connectPromise?: Promise<void>
    private subscriptions: Subscriptions = {}

    private token = ''
    private deviceId = ''
    private isConnected = false
    private currentRequests: {
        [Type in RequestType]?: Requests[Type]
    } = {}

    public onLogout: VoidFunction | undefined

    private constructor() {
        const client = (this.client = new Client(STOMP_CLIENT_SETTINGS))
        client.onUnhandledMessage = (message) => {
            log.log('UNHANDLED MESSAGE', message)
        }
        client.onDisconnect = () => {
            this.isConnected = false
            log.log('DISCONNECT', this.client.connected)
        }
        client.onWebSocketClose = () => {
            this.isConnected = false
            log.log('WEBSOCKET CLOSE', this.client.connected)
        }
        client.onWebSocketError = (error) => {
            log.error('WEBSOCKET ERROR', error)
        }
        client.onStompError = this.onStompError.bind(this)
    }

    get connected(): boolean {
        return this.isConnected
    }

    static getInstance(): Queue {
        if (!Queue.instance) {
            Queue.instance = new Queue()
        }
        return Queue.instance
    }

    public setCredentials({token, deviceId}: {token: string; deviceId: string}): void {
        this.token = token
        this.deviceId = deviceId
    }

    public async connect(): Promise<void> {
        if (!this.connectPromise) {
            this.connectPromise = new Promise((resolve) => {
                this.client.onConnect = () => {
                    this.onConnect()
                    resolve()
                }
                this.client.activate()
            })
        }
        return this.connectPromise
    }

    public disconnect(): void {
        this.unsubscribeAll()
        this.client.deactivate()
    }

    private async onConnect(): Promise<void> {
        this.isConnected = true
        this.connectPromise = undefined

        // resubscribe if needed
        await this.resubscribe()
        // resend requests if needed
        this.resendCurrentRequests()
        log.debug('connected')
    }

    private onStompError({headers: {message}, body}: Frame): void {
        log.error('onStompError', message, body)
    }

    private onMessage<Type extends ResponseType>(
        channel: Type,
        callbacks: Array<(data: Responses[Type]) => void>,
    ): (frame: Frame) => void {
        return (frame) => {
            try {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                delete this.currentRequests[channel]
                const {success, error, result} = JSON.parse(frame.body)
                if (success) {
                    callbacks.forEach((callback) => callback(result))
                    log.debug({
                        action: 'received',
                        channel,
                        payload: frame.body,
                        shortMessage: `received-${channel}`,
                    })
                }
                else if (error) {
                    this.handleError(error)
                }
                else {
                    log.log('request wasn\'t successful', channel, frame)
                }
            }
            catch (error) {
                log.trace('An error occurred while processing response', error)
            }
        }
    }

    private handleError(error: ResponseError): void {
        log.error(error.message)
        switch (error.code) {
            case 401:
                if (typeof this.onLogout === 'function') {
                    this.onLogout()
                }
                break
        }
    }

    private async resubscribe(): Promise<void> {
        const channelCallbacks: ChannelCallbacks = {}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        Object.entries(this.subscriptions).forEach(([channel, {callbacks}]) => {
            channelCallbacks[channel as ResponseType] = callbacks
            delete this.subscriptions[channel as ResponseType]
        })
        if (Object.keys(channelCallbacks).length) {
            await this.subscribeAll(channelCallbacks)
        }
    }

    public async subscribeAll(subscriptions: ChannelCallbacks): Promise<void> {
        const promises = Object.entries(subscriptions).map(([channel, callbacks]) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return this.subscribe(channel as keyof ChannelCallbacks, callbacks as any)
        })
        await Promise.all(promises)
    }

    public async subscribe<Type extends ResponseType>(
        channel: Type,
        callbacks: MaybeArray<CallbackFunction<Type>>,
    ): Promise<void> {
        if (!this.client.connected) {
            await this.connect()
        }

        if (!Array.isArray(callbacks)) {
            callbacks = [callbacks]
        }

        // check if already subscribed and only a new callback is registered
        const oldSubscription = this.subscriptions[channel]
        if (oldSubscription) {
            const {callbacks: oldCallbacks} = oldSubscription as {
                callbacks: Array<CallbackFunction<Type>>
            }
            callbacks.forEach((callback) => {
                if (!oldCallbacks.includes(callback)) {
                    oldCallbacks.push(callback)
                }
            })
            return
        }
        const stompSub = this.client.subscribe(
            `${INCOMING_CHANNELS[channel]}${this.deviceId}`,
            this.onMessage(channel, callbacks),
            {
                'auto-delete': 'true',
                exclusive: 'true',
            },
        )
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        this.subscriptions[channel] = {stompSub, callbacks}
        log.debug({
            action: 'subscribe',
            channel,
            shortMessage: `subscribe-${channel}`,
        })
    }

    public unsubscribeAll(save = false): void {
        Object.keys(this.subscriptions).forEach((channel) => {
            this.unsubscribe(channel as keyof Subscriptions, save)
        })
    }

    public unsubscribe(channel: ResponseType, save = false): void {
        const subscription = this.subscriptions[channel]
        if (subscription && !(save && !subscription.callbacks.length)) {
            subscription.stompSub.unsubscribe()
            delete this.subscriptions[channel]
        }
    }

    public removeCallback<Type extends ResponseType>(channel: Type, callback: CallbackFunction<Type>): void {
        removeFromArray(this.subscriptions[channel]?.callbacks as unknown[], callback)
    }

    public async send<Type extends RequestType>(channel: Type, data: Requests[Type]): Promise<void> {
        if (!this.client.connected) {
            await this.connect()
        }
        const destination = QUEUE_PREFIX + TASKS[channel]
        const payload: DbiApi.BaseRequest<Type> = {
            ...data,
            sourceDeviceId: this.deviceId,
            token: this.token,
            // auth: 'groupAdmin',
        }
        this.client.publish({destination, body: JSON.stringify(payload)})
        this.currentRequests[channel] = data
        log.debug({
            action: 'send',
            channel,
            payload: data,
            shortMessage: `send-${channel}`,
            show: true,
        })
    }

    public async sendBulk<Messages extends {[Type in keyof Requests]?: Requests[Type]}>(
        requests: Messages,
    ): Promise<void> {
        if (!this.client.connected) {
            await this.connect()
        }
        Object.entries(requests).forEach(([type, data]) => {
            this.send(type as RequestType, data as Requests[RequestType])
        })
    }

    private resendCurrentRequests(): void {
        Object.entries(this.currentRequests).forEach(([channel, data]) => {
            this.send(channel as RequestType, data as Requests[RequestType])
        })
    }
}
