function transformClass(
    wagon: Dbi.Wagon,
    previousClass: 'first-class' | 'second-class',
    {wagonClass, isHalfBistro}: Pick<DbiApi.WagonCategory, 'wagonClass' | 'isHalfBistro'>,
): void {
    if (!wagonClass) {
        return
    }

    if (wagonClass === 'both') {
        wagon.classes = [previousClass, getOtherClass(previousClass)]
        return
    }
    wagon.classes = [wagonClass]

    isHalfBistro && wagon.classes.unshift('bistro')
}

function getOtherClass(clazz: 'first-class' | 'second-class'): 'first-class' | 'second-class' {
    return clazz === 'first-class' ? 'second-class' : 'first-class'
}

function transformEngine({type, splitted}: Pick<DbiApi.WagonCategory, 'type' | 'splitted'>): Dbi.Wagon['engineType'] {
    if (type === 'loco' || type.includes('engine')) {
        return (splitted ? `${type}-splitted` : type) as Dbi.Wagon['engineType']
    }
}

function transformWagons(wagons: DbiApi.Wagon[]): Dbi.Wagon[] {
    let previousClass: 'first-class' | 'second-class'
    return wagons.map((wagon) => {
        const {
            isFirst: first,
            isLast: last,
            orderNumber,
            category,
            equipment,
            position: {totalLength: length},
        } = wagon

        const result: Dbi.Wagon = {
            orderNumber,
            first,
            last,
            length,
            equipment: equipment.filter(({name}) => name !== 'KLIMA').map(({name}) => name.toLowerCase()),
        }

        if (!Array.isArray(category)) {
            result.engineType = transformEngine(category)

            transformClass(result, previousClass, category)

            const {wagonClass} = category
            if (wagonClass === 'first-class' || wagonClass === 'second-class') {
                previousClass = wagonClass
            }
        }

        return result
    })
}

function transformVehicles(trainType: string, vehicles: DbiApi.Vehicle[]): Dbi.Vehicle[] {
    return vehicles.map(({trafficNumber, series, originName, destinationName, wagonList}) => {
        const wagons = transformWagons(wagonList)
        return {
            // FIXME:
            // . that is a quick hack to display the train name. it might can't be trusted
            name: `${trainType} ${trafficNumber}`,
            origin: originName,
            destination: destinationName,
            series,
            wagons,
            sectors: {
                start: wagonList[0].sector,
                end: wagonList[wagonList.length - 1].sector,
            },
        }
    })
}

function transformSectors(
    sectors: Array<{
        name: string
        position: Pick<DbiApi.SectorPosition, 'totalLength'>
    }>,
): Dbi.StationSector[] {
    return sectors.map(({name, position}) => ({
        name,
        length: position.totalLength,
    }))
}

export function transformWagonOrder(response: DbiApi.WagonOrderResponse['trainInfo']): Dbi.WagonOrder {
    const {type, lastUpated, orientation, isReversed, istInformation, stop, vehicleList} = response
    return {
        lastUpdated: new Date(lastUpated),
        orientation,
        reversed: isReversed,
        validated: istInformation,
        sectors: transformSectors(stop.sectorList),
        vehicles: transformVehicles(type, vehicleList),
    }
}
