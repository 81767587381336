export type TrafficType = {
    type: DbiApi.TrafficType
    title: string
    short: string
}

export const TRAFFIC_TYPES: ImmutableArray<TrafficType> = [
    {type: 'longDistance', title: 'Fernverkehr', short: 'FV'},
    {type: 'shortDistance', title: 'Nahverkehr', short: 'NV'},
    {type: 'sTrain', title: 'S-Bahn', short: 'S'},
    {type: 'publicTransportation', title: 'ÖPNV', short: 'ÖPNV'},
] as const
Object.freeze(TRAFFIC_TYPES)

export const TRANSPORT_TYPES_ALL = 1023
export const TRANSPORT_TYPES_SHORT_DISTANCE = 1016
export const TRANSPORT_TYPES_TRAINS = 31

export const TRANSPORT_TYPES: ImmutableArray<{
    order: number
    hafas: {
        id: ValuesOf<Dbi.HafasTrafficType>
        name: keyof Dbi.HafasTrafficType
    }
    title: string
    // basicly 2 ^ hafasId
    bitmask: number
}> = [
    {
        order: 0,
        hafas: {
            id: 0,
            name: 'ice',
        },
        title: 'ICE',
        bitmask: 1,
    },
    {
        order: 1,
        hafas: {
            id: 1,
            name: 'ecIc',
        },
        title: 'IC/EC',
        bitmask: 2,
    },
    {
        order: 2,
        hafas: {
            id: 2,
            // that's actually Interregio, but since it's a 'D-Zug' for the client, better use that naming
            name: 'd',
        },
        title: 'D-Zug',
        bitmask: 4,
    },
    {
        order: 3,
        hafas: {
            id: 3,
            name: 'nv',
        },
        title: 'NV',
        bitmask: 8,
    },
    {
        order: 4,
        hafas: {
            id: 4,
            name: 's',
        },
        title: 'S',
        bitmask: 16,
    },
    {
        order: 5,
        hafas: {
            id: 7,
            name: 'u',
        },
        title: 'U',
        bitmask: 128,
    },
    {
        order: 6,
        hafas: {
            id: 9,
            name: 'taxi',
        },
        title: 'Taxi',
        bitmask: 512,
    },
    {
        order: 7,
        hafas: {
            id: 8,
            name: 'tram',
        },
        title: 'Tram',
        bitmask: 256,
    },
    {
        order: 8,
        hafas: {
            id: 5,
            name: 'bus',
        },
        title: 'Bus',
        bitmask: 32,
    },
    {
        order: 9,
        hafas: {
            id: 6,
            name: 'ship',
        },
        title: 'Schiff',
        bitmask: 64,
    },
] as const
Object.freeze(TRANSPORT_TYPES)

export const TRANSPORT_TYPE_NAMES = TRANSPORT_TYPES.map(({hafas: {name}}) => name)

export type TransportType = typeof TRANSPORT_TYPES[number]
