export const TASKS: PartialRecord<keyof DbiApi.Requests, string> = {
    Settings: 'TASK_SETTINGS',
    SearchLeaderboard: 'TASK_CONNECTION_SEARCH_LEADERBOARD',
    Connection: 'TASK_HAFAS_TRIP',
    StationPanel: 'TASK_RIS_STATION',
    TrainDetail: 'TASK_RIS_TRAIN',
    ConnectingTrains: 'TASK_RIS_TRAIN_CONNECTION',
    WagonOrder: 'TASK_WAGON_ORDER',
    HafasLocation: 'TASK_HAFAS_LOCATION_NAME',
    Certificate: 'TASK_PRINT',
} as const

export const INCOMING_CHANNELS: PartialRecord<keyof DbiApi.Responses, string> = {
    Settings: '/queue/QUEUE_SETTINGS_',
    SearchLeaderboard: '/queue/QUEUE_CONNECTION_SEARCH_LEADERBOARD_',
    Connection: '/queue/QUEUE_HAFAS_TRIP_',
    StationPanel: '/queue/QUEUE_RIS_STATION_',
    TrainDetail: '/queue/QUEUE_RIS_TRAIN_',
    ConnectingTrains: '/queue/QUEUE_RIS_TRAIN_CONNECTION_',
    WagonOrder: '/queue/QUEUE_WAGON_ORDER_',
    HafasLocation: '/queue/QUEUE_HAFAS_LOCATION_NAME_',
    Certificate: '/queue/QUEUE_PRINT_DELAY_',
} as const
