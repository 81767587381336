








import {Component, Prop, Vue} from 'vue-property-decorator'

@Component({
    name: 'IconTextButton',
})
export default class IconTextButton extends Vue {
    @Prop({type: String, required: true})
    private readonly icon!: string

    @Prop({type: String, default: 'button'})
    private readonly type!: string
}
