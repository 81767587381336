import {Mutations} from 'vuex-smart-module'
import type {RootState, StationPanelSettings} from './state'

import {typedStorage} from '@/helper'
import {transformSearchLeaderboard} from '@/transforms'
import {Queue} from '@/api'

const {set, setAll, removeAll} = typedStorage

export class RootMutations extends Mutations<RootState> {
    queue!: Queue
    initialized(isIt: boolean): void {
        this.state.initialized = isIt
    }

    menuOpen(open: boolean): void {
        this.state.menuOpen = open
        if (!open) {
            this.state.configOpen = false
        }
    }

    configOpen(open: boolean): void {
        this.state.configOpen = open
    }

    login({token, deviceId}: {token: string; deviceId: string}): void {
        this.state.token = token
        this.state.deviceId = deviceId
        setAll({token, deviceId})
    }

    logout(): void {
        this.state.token = ''
        this.state.deviceId = ''
        removeAll(['token', 'deviceId'])
    }

    settings({threshold, trafficTypes}: DbiApi.SettingsResponse): void {
        Object.assign(this.state.threshold, threshold)
        Object.assign(this.state.trafficTypes, trafficTypes)
    }

    homeStation(station: Dbi.Station): void {
        this.state.homeStation = station
        set('homeStation', station)
    }

    detailedStation(station: Dbi.Station): void {
        this.state.detailedStation = station
        set('detailedStation', station)
    }

    stationPanelSettings(settings: Partial<StationPanelSettings>): void {
        const {stationPanel} = this.state
        Object.assign(stationPanel, settings)
        set('stationPanelSettings', stationPanel)
    }

    searchLeaderboard(searches: DbiApi.SearchLeaderboardResponse['searchLeaderboard']): void {
        this.state.connectionSearches.station = searches.map(transformSearchLeaderboard)
    }
}
