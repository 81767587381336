import type {SearchParams, SearchTransportType, Stopover} from '@/transforms'
import {TRANSPORT_TYPES} from '@common/const'

const DEFAULT_SEARCH_PARAMS_ELABORATION: SearchParams['elaboration'] = {
    direct: false,
    preferFast: true,
    bikeCarriage: false,
    changeTime: {
        type: 'variable',
        value: 100,
    },
    products: createNewProductSelection(),
    via: [] as SearchParams['elaboration']['via'],
} as const
Object.freeze(DEFAULT_SEARCH_PARAMS_ELABORATION)

export function createNewProductSelection(oldSelection?: SearchTransportType): SearchTransportType {
    return oldSelection
        ? {...oldSelection, custom: oldSelection.custom.slice()}
        : {
            selection: 'all',
            custom: TRANSPORT_TYPES.slice(),
        }
}

export function createNewStopover(oldStopOver?: Stopover): Stopover {
    return oldStopOver
        ? {
            ...oldStopOver,
            products: createNewProductSelection(oldStopOver.products),
        }
        : {
            station: null,
            time: null,
            products: createNewProductSelection(),
        }
}

export function createNewSearchElaboration(oldElaboration?: SearchParams['elaboration']): SearchParams['elaboration'] {
    if (oldElaboration) {
        return {
            ...oldElaboration,
            changeTime: {...oldElaboration.changeTime},
            products: createNewProductSelection(oldElaboration.products),
            via: oldElaboration.via.map((stopover) => createNewStopover(stopover)),
        }
    }

    return {
        direct: false,
        preferFast: true,
        bikeCarriage: false,
        changeTime: {
            type: 'variable',
            value: 100,
        },
        products: createNewProductSelection(),
        via: [] as SearchParams['elaboration']['via'],
    }
}

export function isSearchElaborationChanged(elaboration: SearchParams['elaboration']): boolean {
    const {
        direct,
        preferFast,
        bikeCarriage,
        changeTime: {type, value},
        products,
        via,
    } = elaboration
    return (
        direct ||
        !preferFast ||
        bikeCarriage ||
        via.length !== 0 ||
        value !== 100 ||
        type !== 'variable' ||
        isProductsChanged(products)
    )
}

const TRANSPORT_TYPES_COUNT = TRANSPORT_TYPES.length
function isProductsChanged({selection, custom}: SearchTransportType): boolean {
    return custom.length !== TRANSPORT_TYPES_COUNT || selection !== 'all'
}
