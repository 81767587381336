import type {RouteConfig} from 'vue-router'

import Log from '@/views/Log.vue'

export const routes: Array<RouteConfig> = [
    {
        path: '/login',
        name: 'login',
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
        meta: {
            public: true,
        },
    },
    {
        path: '/station-panel',
        name: 'station-panel',
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        component: () => import(/* webpackChunkName: "stationPanel" */ '@/views/StationPanel.vue'),
    },
    {
        path: '/connection-search',
        name: 'connection-search',
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        component: () => import(/* webpackChunkName: "connectionSearch" */ '@/views/ConnectionSearch.vue'),
    },
    {
        path: '/station-details',
        name: 'station-details',
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        component: () => import(/* webpackChunkName: "stationDetails" */ '@/views/StationDetailsView.vue'),
    },
    {
        path: '/station-map',
        name: 'station-map',
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        component: () => import(/* webpackChunkName: "stationMap" */ '@/views/StationMap.vue'),
    },
    {
        path: '/log',
        name: 'log',
        component: Log,
    },
    {
        path: '/privacy',
        name: 'privacy',
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        component: () => import(/* webpackChunkName: "legal" */ '@/views/Privacy.vue'),
        meta: {
            public: true,
        },
    },
    {
        path: '/imprint',
        name: 'imprint',
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        component: () => import(/* webpackChunkName: "legal" */ '@/views/Imprint.vue'),
        meta: {
            public: true,
        },
    },
    {
        path: '/error',
        name: 'error',
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        component: () => import(/* webpackChunkName: "legal" */ '@/views/Error.vue'),
        meta: {
            public: true,
        },
    },
    {
        path: '*',
        redirect: 'station-panel',
    },
]
