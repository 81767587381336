/** in millies */
export const THRESHOLD_STOP_TIME = 60000

export const THRESHOLD_TIMEOUT = 10000

export const TIME_TYPES = [
    {type: 'departure', title: 'Abfahrt'},
    {type: 'arrival', title: 'Ankunft'},
] as const
Object.freeze(TIME_TYPES)
