export function isEarlier(timeEntry: {time: Date} | undefined, now: Date, offset?: number): boolean {
    if (!offset || Number.isNaN(offset)) {
        offset = 0
    }
    return !!timeEntry && timeEntry.time.valueOf() + offset < now.valueOf()
}

export function isLater(timeEntry: {time: Date} | undefined, now: Date, offset?: number): boolean {
    if (!offset || Number.isNaN(offset)) {
        offset = 0
    }

    return !!timeEntry && timeEntry.time.valueOf() + offset > now.valueOf()
}

export function isPastDay(date: Date): boolean {
    const today = new Date()
    today.setHours(0)
    today.setMinutes(0)
    today.setSeconds(0)
    today.setMilliseconds(0)
    return date < today
}

export function isSameDay(dateA: Date, dateB: Date): boolean {
    return (
        dateA.getDate() === dateB.getDate() &&
        dateA.getMonth() === dateB.getMonth() &&
        dateA.getFullYear() === dateB.getFullYear()
    )
}

export function isToday(date: Date): boolean {
    return isSameDay(date, new Date())
}

/** returns day number of given day (or today), but starting with monday */
export function getDayOfWeek(date = new Date()): number {
    const weekDay = date.getDay()
    return weekDay === 0 ? 6 : weekDay - 1
}
