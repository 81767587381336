
import type {FunctionalComponentOptions, VNode} from 'vue'
import {mergeData} from 'vue-functional-data-merge'

const KEY_PREFIX = 'notif-indi-'

const NOTIFICATION_TYPES = {
    warning: {color: 'red', icon: 'exclamation-small'},
    reachable: {color: 'green', icon: 'checkmark'},
    unreachable: {color: 'red', icon: 'cross'},
} as const

const NOTIFICATION_TYPE_NAMES = Object.keys(NOTIFICATION_TYPES)

export type NotificationType = keyof typeof NOTIFICATION_TYPES
export type NotificationOptions = {
    type: NotificationType
    count?: number
}

const component: FunctionalComponentOptions<NotificationOptions> = {
    name: 'NotificationIndicator',
    functional: true,
    props: {
        type: {
            required: true,
            validator: (type: unknown) => typeof type === 'string' && NOTIFICATION_TYPE_NAMES.includes(type),
        },
        count: {
            type: Number,
        },
    },
    render(createElement, {data, props}) {
        const {type, count} = props
        const {color, icon} = NOTIFICATION_TYPES[type]

        const hasCount = count !== undefined && count !== null

        const key = KEY_PREFIX + hasCount ? `${color}-${count}` : type

        const componentData = {
            staticClass: `notification-indicator notification-type-${type} ${color}`,
            key,
        }

        const childElement = hasCount ? ((count as unknown) as VNode) : createElement('svg-sprite', {props: {icon}})

        return createElement('span', mergeData(data, componentData), [childElement])
    },
}
export default component
