




















import {Component, Vue, Ref} from 'vue-property-decorator'

import DbiMenu from '@/components/menu/DbiMenu.vue'
import DbiLoader from '@common/components/ui/DbiLoader.vue'
import DbiToast from '@/components/misc/DbiToast.vue'

import BottomBar from '@/components/layout/BottomBar.vue'
import type {BottomBarItem} from '@/components/layout/BottomBar.vue'

import {URLS} from '@/const'

const BOTTOM_BAR_ITEMS: BottomBarItem[] = [
    {
        toName: 'connection-search',
        icon: 'connection-search',
        title: 'Verbindungssuche',
    },
    {toName: 'station-panel', icon: 'timetable', title: 'Bahnhofstafel'},
    {toName: 'station-details', icon: 'station', title: 'Bahnhofsinformationen'},
    {toName: 'station-map', icon: 'destination', title: 'Bahnhofsplan'},
]

@Component({
    components: {
        DbiMenu,
        DbiLoader,
        BottomBar,
        DbiToast,
    },
})
export default class App extends Vue {
    @Ref()
    private readonly routerView!: Vue & {handleBottomBarClick?: VoidFunction}

    connected = false
    bottomBarItems = BOTTOM_BAR_ITEMS
    urls = URLS

    get showLoader(): boolean {
        return !(this.isPublicPage || this.$dbiStore.state.initialized)
    }

    get isPublicPage(): boolean {
        return this.$route.meta.public && !this.$dbiStore.getters.isLoggedIn
    }

    created(): void {
        this.$queue.onLogout = () => {
            this.$dbiStore.actions.logout()
            this.$router.replace({name: 'login'})
        }
    }

    bottomBarExtraClick(): void {
        if (typeof this.routerView.handleBottomBarClick === 'function') {
            this.routerView.handleBottomBarClick()
        }
    }
}
