export function replaceArrayContent<T>(target: Array<T>, newContent: Array<T>): void {
    target.splice(0, target.length)
    target.push(...newContent)
}

export function clearArray(arr: Array<unknown>): void {
    arr.splice(0, arr.length)
}

export function removeFromArray<T extends unknown>(arr: Array<T> | undefined, item: T): void {
    const idx = arr?.indexOf(item)
    if (typeof idx === 'number' && idx >= 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        arr.splice(idx, 1)
    }
}

export function cutIntoPieces<T>(array: T[], sliceSize: number): T[][] {
    const pieces = []
    const piecesCount = Math.ceil(array.length / sliceSize)
    for (let i = 0; i < piecesCount; i++) {
        const start = i * 3
        pieces.push(array.slice(start, start + sliceSize))
    }
    return pieces
}

export function ensureArray<T>(arr: T | T[]): T[] {
    return Array.isArray(arr) ? arr : [arr]
}

export type FilterObject<T extends Record<string, unknown>> = {
    [Key in keyof T]: MaybeArray<T[Key]> | ((value: T[Key]) => boolean)
}
export function filter<T extends Record<string, unknown>, F extends FilterObject<T>>(arr: T[], filterObject: F): T[] {
    const filterKeys = Object.keys(filterObject)
    return arr.filter((item) =>
        filterKeys.every((filterKey) => {
            const filter = filterObject[filterKey]
            const value = item[filterKey] as T[typeof filterKey]
            if (typeof filter === 'function') {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return filter(value)
            }
            if (Array.isArray(filter)) {
                return filter.includes(value)
            }
            return filter === value
        }),
    )
}

export function applyFilterArray<T extends unknown>(arr: T[], filters: Array<(value: T) => boolean>): T[] {
    return arr.filter((item) => filters.every((filter) => filter(item)))
}
