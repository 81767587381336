import {Getters} from 'vuex-smart-module'
import {RootState} from './state'
import {isDelayed} from '@/helper'
import {HALT_OPTIONS, HALT_OPTIONS_VALUES, TRAVELSTATE_OPTIONS, TRAVELSTATE_OPTIONS_VALUES} from '@/const'

export class RootGetters extends Getters<RootState> {
    get isLoggedIn(): boolean {
        return !!this.state.token
    }

    get readyToOpen(): boolean {
        const {deviceId, token, homeStation} = this.state
        return !!(deviceId && token && homeStation)
    }

    get selectedTrainTypes(): string[] {
        const {
            trafficTypes,
            stationPanel: {trafficTypes: selectedTrafficTypes},
        } = this.state
        return selectedTrafficTypes.map((type) => trafficTypes[type]).flat()
    }

    get stationPanelFilterArray(): Array<(train: Dbi.Train) => boolean> {
        const {minDelay, halt, travelState, changedTracksOnly} = this.state.stationPanel
        const {selectedTrainTypes} = this.getters
        const filters: Array<(train: Dbi.Train) => boolean> = [({type}) => selectedTrainTypes.includes(type)]

        if (minDelay > 0) {
            filters.push(({arrival, departure}) => isDelayed(arrival, minDelay) || isDelayed(departure, minDelay))
        }

        if (halt !== HALT_OPTIONS_VALUES.All) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const filter = HALT_OPTIONS.find(({value}) => value === halt).filter
            filters.push(filter)
        }

        if (travelState !== TRAVELSTATE_OPTIONS_VALUES.All) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const filter = TRAVELSTATE_OPTIONS.find(({value}) => value === travelState).filter
            filters.push(filter)
        }

        if (changedTracksOnly) {
            filters.push(({track: {changed}}) => changed)
        }

        return filters
    }
}
