import type {PreviousSearch} from '@/transforms'
import {typedStorage} from '@/helper'
import {HALT_OPTIONS_VALUES, TRAVELSTATE_OPTIONS_VALUES} from '@/const'

const {get, getAndFill} = typedStorage

export type StationPanelSettings = {
    station: Dbi.Station
    diffBack: number
    diffForth: number
    minDelay: number
    trafficTypes: Array<DbiApi.TrafficType>
    halt: HALT_OPTIONS_VALUES
    travelState: TRAVELSTATE_OPTIONS_VALUES
    changedTracksOnly: boolean
}

export const threshold: DbiApi.SettingsResponse['threshold'] = {
    delayCertificate: 0,
    delayCustomerScreen: 0,
    delayPrint: 0,
    delayRed: 0,
    delayTerminal: 0,
}

export const DEFAULT_STATION_PANEL_SETTINGS: Omit<StationPanelSettings, 'station'> = {
    diffBack: 15,
    diffForth: 60,
    minDelay: 0,
    trafficTypes: ['longDistance', 'shortDistance', 'sTrain'],
    halt: HALT_OPTIONS_VALUES.All,
    travelState: TRAVELSTATE_OPTIONS_VALUES.All,
    changedTracksOnly: false,
}

export class RootState {
    initialized = false

    menuOpen = false
    configOpen = false

    deviceId = get('deviceId', '')
    token = get('token', '')

    threshold = threshold

    trafficTypes: Record<DbiApi.TrafficType, string[]> = {
        longDistance: [],
        shortDistance: [],
        sTrain: [],
        publicTransportation: [],
    }

    homeStation = get('homeStation', null)

    detailedStation = get('detailedStation', this.homeStation) as Dbi.Station

    stationPanel: StationPanelSettings = getAndFill('stationPanelSettings', {
        ...DEFAULT_STATION_PANEL_SETTINGS,
        station: this.homeStation,
    }) as StationPanelSettings

    connectionSearches: {
        station: PreviousSearch[]
    } = {
        station: [],
    }
}
