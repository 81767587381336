export function pad(value: string | number, maxLength: number, fillString: string): string {
    if (typeof value === 'number') {
        value = value.toString()
    }
    return value.padStart(maxLength, fillString)
}

export function padNumber(number: number, amount = 2): string {
    return pad(number, amount, '0')
}

export function hash(string: string): number {
    let hash = 0
    for (let i = 0; i < string.length; i++) {
        hash = (Math.imul(31, hash) + string.charCodeAt(i)) | 0
    }
    return Math.abs(hash)
}
