import Vue from 'vue'
import VueRouter from 'vue-router'

import {routes} from './routes'

import {rootContext} from '@/store'
const {actions, getters} = rootContext

Vue.use(VueRouter)

const scrollPositions: Record<string, {x: number; y: number}> = {}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior({hash, name}, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }
        else if (hash) {
            return {selector: hash}
        }
        else if (name && scrollPositions[name]) {
            return scrollPositions[name]
        }
        return {x: 0, y: 0}
    },
    routes,
})

router.beforeEach((to, from, next) => {
    if (to.meta.public) {
        next()
    }
    else if (!getters.readyToOpen) {
        actions.logout()
        next({name: 'login'})
    }
    else {
        next()
    }
})

router.afterEach((to, {name}) => {
    if (name) {
        scrollPositions[name] = {
            x: window.pageXOffset,
            y: window.pageYOffset,
        }
    }
})

export default router
