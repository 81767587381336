import {Actions} from 'vuex-smart-module'
import type {RootState, StationPanelSettings} from './state'
import type {RootGetters} from './getters'
import type {RootMutations} from './mutations'

import {Queue} from '@/api'

import {DEFAULT_STATION_PANEL_SETTINGS} from './state'

export class RootActions extends Actions<RootState, RootGetters, RootMutations, RootActions> {
    queue!: Queue

    async $init(): Promise<void> {
        this.queue = Queue.getInstance()

        const {token, deviceId} = this.state
        if (token && deviceId) {
            this.dispatch('initQueue')
        }
        else {
            this.commit('logout')
        }
    }

    async initQueue(): Promise<void> {
        const {
            queue,
            commit,
            state: {homeStation, token, deviceId},
        } = this
        queue.setCredentials({token, deviceId})
        await queue.subscribeAll({
            Settings: (settings) => {
                commit('settings', settings)
                commit('initialized', true)
            },
            SearchLeaderboard: ({searchLeaderboard}) => {
                commit('searchLeaderboard', searchLeaderboard)
            },
        })
        queue.send('Settings', {action: 'get'})

        if (homeStation) {
            queue.send('SearchLeaderboard', {action: 'get', stationName: homeStation.name})
        }
    }

    login(payload: {token: string; deviceId: string}): void {
        // TODO:
        // . for now simply forward the token. later the login should be done here.
        this.commit('login', payload)
        this.dispatch('initQueue')
    }

    logout(): void {
        this.commit('logout')
        this.queue.disconnect()
    }

    homeStation(station: Dbi.Station): void {
        const {
            state: {
                homeStation: oldStation,
                detailedStation,
                stationPanel: {station: stationPanelStation},
            },
        } = this

        this.queue.send('SearchLeaderboard', {action: 'get', stationName: station.name})
        this.commit('homeStation', station)

        if (!oldStation || !stationPanelStation || stationPanelStation?.eva === oldStation?.eva) {
            this.dispatch('stationPanelSettings', {station})
        }
        if (!oldStation || !detailedStation || detailedStation?.eva === oldStation?.eva) {
            this.dispatch('detailedStation', station)
        }
    }

    detailedStation(station: Dbi.Station): void {
        this.commit('detailedStation', station)
    }

    stationPanelSettings(settings: Partial<StationPanelSettings>): void {
        this.commit('stationPanelSettings', settings)
    }

    resetStationPanelSettings(): void {
        console.log('reset', DEFAULT_STATION_PANEL_SETTINGS)
        this.commit('stationPanelSettings', {
            ...DEFAULT_STATION_PANEL_SETTINGS,
            station: this.state.homeStation,
        } as StationPanelSettings)
    }
}
