import {createDecorator, VueDecorator} from 'vue-class-component'
import {Queue, ResponseType} from '@/api'

const queue = Queue.getInstance()

export function Subscription(channel: ResponseType): VueDecorator {
    return createDecorator((componentOptions, functionName) => {
        const methods = componentOptions.methods
        if (!(methods && methods[functionName])) {
            return
        }
        componentOptions.mixins ||= []
        componentOptions.mixins.push({
            created() {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                queue.subscribe(channel, this[functionName])
            },
            destroyed() {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                queue.removeCallback(channel, this[functionName])
            },
        })
    })
}
