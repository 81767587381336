import pick from 'object.pick'
import {formatDate} from '@common/lib'

export function createCertificateRequest({
    variant,
    station,
    train,
    qrCode,
}: {
    train: Dbi.Train
    station: Dbi.Station
    variant: Dbi.CertificateVariant
    qrCode: string
}): DbiApi.CertificateRequest {
    const data: Pick<DbiApi.CertificateRequest['data'], 'station' | 'train'> = {
        station,
        train: pick(train, ['name', 'number', 'line', 'type'] as (keyof Dbi.Train)[]),
    }

    function addTime(field: 'arrival' | 'departure'): void {
        const timeEntry = train[field]
        if (timeEntry) {
            const {soll, prog, diff, canceled} = timeEntry
            ;(data as DbiApi.CertificateRequest['data'])[field] = {
                soll: formatDate(soll) as string,
                prog: formatDate(prog),
                diff: diff,
                diffRis: diff,
                status: canceled ? 'Ausf' : 'Normal',
            }
        }
    }
    addTime('arrival')
    addTime('departure')

    return {
        variant,
        action: 'get-delay',
        trainNumber: train.number,
        stationName: station.name,
        arrivalDate: (data as DbiApi.CertificateRequest['data']).arrival?.soll,
        departureDate: (data as DbiApi.CertificateRequest['data']).departure?.soll,
        qrCode,
        data: data as DbiApi.CertificateRequest['data'],
        isLocal: true,
        pdfPrint: false,
    }
}
