










import {Component, Vue, Prop} from 'vue-property-decorator'

import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock'

@Component({
    name: 'DbiLayer',
})
export default class DbiLayer extends Vue {
    @Prop({type: Boolean, default: false})
    readonly showBottomBar!: boolean

    @Prop({type: String, default: 'top'})
    readonly justifyContent!: string

    mounted(): void {
        disableBodyScroll(this.$el)
    }

    beforeDestroy(): void {
        enableBodyScroll(this.$el)
    }

    activated(): void {
        disableBodyScroll(this.$el)
    }

    deactivated(): void {
        enableBodyScroll(this.$el)
    }
}
