import Vue from 'vue'
import * as Vuex from 'vuex'
import {createStore, Module} from 'vuex-smart-module'

import {RootState} from './state'
import {RootGetters} from './getters'
import {RootMutations} from './mutations'
import {RootActions} from './actions'

Vue.use(Vuex)

export const rootModule = new Module({
    state: RootState,
    getters: RootGetters,
    mutations: RootMutations,
    actions: RootActions,
})

export const store = createStore(rootModule, {
    strict: process.env.NODE_ENV !== 'production',
})

export default store

export const rootContext = rootModule.context(store)
