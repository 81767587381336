import {isEarlier, isLater} from '@common/lib'
import {THRESHOLD_STOP_TIME} from '@common/const'

export function findTrainPosition(route: Array<Dbi.TrainDetailStop>): void {
    const now = new Date()

    const firstStop = route[0]
    const lastStop = route[route.length - 1]

    if (
        isLater(firstStop.departure, now, -THRESHOLD_STOP_TIME) ||
        isEarlier(lastStop.arrival, now, THRESHOLD_STOP_TIME)
    ) {
        return
    }

    for (const stop of route) {
        delete stop.trainAtStation
        delete stop.trainBeforeStation

        const {canceled, arrival, departure, firstStation} = stop
        if (canceled || isEarlier(departure, now)) {
            continue
        }

        const field = isEarlier(arrival, now) || firstStation ? 'trainAtStation' : 'trainBeforeStation'
        stop[field] = true
        break
    }
}

export function getTrainInfoIcon(message: Dbi.HimMessage): string {
    const infoTypes = [
        {key: 'FB', title: 'plaetzefahrrad'},
        {key: 'RG', title: 'plaetzeschwerbeh'},
        {key: 'K2', title: 'nurzweiteklasse'},
        {key: 'BR', title: 'bistro'},
        {key: 'BT', title: 'bistro'},
        {key: 'FR', title: 'plaetzefahrrad'},
        {key: 'FS', title: 'plaetzerollstuhl'},
        {key: 'SI', title: 'plaetzerollstuhl'},
        {key: 'ER', title: 'plaetzerollstuhl'},
        {key: 'EH', title: 'plaetzerollstuhl'},
        {key: 'KI', title: 'familie'},
        {key: 'EA', title: 'info'},
        {key: 'CK', title: 'info'},
        {key: 'KL', title: 'info'},
        {key: 'IZ', title: 'info'},
        {key: 'MP', title: 'info'},
        {key: 'IT', title: 'info'},
        {key: 'RC', title: 'info'},
    ]

    const icon = infoTypes.find(({key}) => key === message.key)

    return icon ? `equipment-${icon.title}` : 'equipment-info'
}

export function setTrainShortcutInfos(train: Dbi.Train): void {
    const {arrival, departure} = train
    train.time = (arrival?.soll || departure?.soll) as Date
    train.canceledPartly = !!(arrival?.canceled || departure?.canceled)
    train.canceled = !!((!arrival || arrival.canceled) && (!departure || departure.canceled))
    train.delayed = !!(arrival?.delayed || departure?.delayed)
    train.timeVerified = !!((!arrival || arrival.verified) && (!departure || departure.verified))
}

export function updateTrainInfo(train?: Dbi.Train, details?: Dbi.TrainDetail): void {
    if (!(train && details)) {
        return
    }

    const {
        station: {eva},
        track,
        arrival,
        departure,
    } = train
    const currentStationStop = details.route.find((stop) => stop.station.eva === eva)
    if (!currentStationStop) {
        return
    }
    const {track: detailTrack, arrival: detailArrival, departure: detailDeparture} = currentStationStop
    detailArrival && Object.assign(arrival, detailArrival)
    detailDeparture && Object.assign(departure, detailDeparture)
    if (detailTrack) {
        const {soll, prog} = detailTrack
        if (soll) {
            track.soll = soll
        }
        if (prog) {
            track.prog = prog
        }
    }
    setTrainShortcutInfos(train)
}
