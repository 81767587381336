import {transformTimeEntry} from './time'
import {transformTrack} from './track'

export function transformConnectingTrains(apiTrains: DbiApi.ConnectingTrain[]): Dbi.ConnectingTrain[] {
    return apiTrains.map((apiTrain) => {
        const {trainName, trainNr, endStationEva, endStationName, track} = apiTrain

        const destination = {
            eva: endStationEva,
            name: endStationName,
        }

        const departure = transformTimeEntry(apiTrain)

        return {
            trainName,
            trainNr,
            destination,
            departure,
            track: transformTrack(track),
        }
    })
}
