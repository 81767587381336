










































import {Component, Vue} from 'vue-property-decorator'

import DbiLayer from '@/components/layout/DbiLayer.vue'
import DbiLayerTopBar from '@/components/layout/DbiLayerTopBar.vue'
import DbiMenuItem from '@/components/menu/DbiMenuItem.vue'
import StationSelect from '@/components/form/StationSelect.vue'

@Component({
    name: 'DbiMenu',
    components: {
        DbiLayer,
        DbiLayerTopBar,
        DbiMenuItem,
        StationSelect,
    },
})
export default class DbiMenu extends Vue {
    buttons = {
        left: {
            icon: 'arrow-left',
            title: 'schließen',
            onClick: this.close.bind(this),
        },
    }

    showStationSelect = false

    get configOpen(): boolean {
        return this.$dbiStore.state.configOpen
    }

    get station(): Dbi.Station {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.$dbiStore.state.homeStation!
    }

    set station(station: Dbi.Station) {
        this.showStationSelect = false
        this.$dbiStore.actions.homeStation(station)
    }

    selectHomeStation(): void {
        this.showStationSelect = true
    }

    closeStationSelect(): void {
        this.showStationSelect = false
    }

    close(): void {
        this.$dbiStore.mutations.configOpen(false)
    }

    closeMenu(): void {
        this.$dbiStore.mutations.menuOpen(false)
    }

    logout(): void {
        this.$dbiStore.actions.logout()
        this.$router.replace({name: 'login'})
        this.closeMenu()
    }
}
