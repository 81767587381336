





























































import {Component, Prop, Ref, Model, Watch, Vue} from 'vue-property-decorator'

import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock'

import IconButton from '@common/components/baseComponents/IconButton.vue'

export type DbiSuggestOptions<ValueType extends string | Record<string, unknown>> = {
    id: string
    hint?: string
    itemTitle?: ValueType extends string ? void : keyof ValueType
    clearOnOpen?: boolean
    placeholder?: string
    hideLabel?: boolean
    forceSelection?: boolean
    name?: string
    reset?: {
        icon: string
        title: string
        value: ValueType
    }
}

const OPTION_DEFAULTS = {
    itemTitle: 'title',
    clearOnOpen: true,
    hideLabel: false,
    forceSelection: false,
}

@Component({
    name: 'DbiSuggestInput',
    components: {
        IconButton,
    },
})
export default class DbiSuggestInput<ValueType extends string | Record<string, unknown>> extends Vue {
    @Model('input')
    private readonly value!: ValueType

    @Prop({type: Object, required: true})
    private readonly options!: DbiSuggestOptions<ValueType>

    @Prop({type: Array})
    private readonly suggestions!: Array<ValueType> | null

    @Ref()
    private readonly label!: HTMLLabelElement

    @Ref()
    private readonly inputField!: HTMLInputElement

    open = false
    valueTitle = ''

    get innerOptions(): DbiSuggestOptions<ValueType> {
        return Object.assign({}, OPTION_DEFAULTS, this.options)
    }

    get inputId(): string {
        return `dbi-suggest-${this.innerOptions.id}`
    }

    getTitle(value: ValueType): string {
        return typeof value === 'string'
            ? value
            : ((value[this.innerOptions.itemTitle as keyof ValueType] as unknown) as string)
    }

    setInput(title: string): void {
        this.valueTitle = title
        this.$emit('user-input', title)
    }

    @Watch('value', {immediate: true})
    setValueTitle(value?: ValueType): void {
        this.valueTitle = value ? this.getTitle(value) : ''
    }

    public focus(): void {
        this.onFocus()
        this.$nextTick(() => {
            this.inputField.focus()
        })
    }

    onFocus(): void {
        if (this.innerOptions.clearOnOpen) {
            this.valueTitle = ''
        }
        this.open = true
        disableBodyScroll(this.$el)
    }

    onInput(event: InputEvent): void {
        const {value} = event.target as HTMLInputElement
        this.setInput(value)
    }

    sendSelection(selection: ValueType): void {
        this.$emit('input', selection)
        this.close()
    }

    clear(): void {
        this.inputField.focus()
        this.setInput('')
        this.suggestions && this.suggestions.splice(0)
    }

    close(): void {
        this.setValueTitle(this.value)
        this.open = false
        this.$emit('close')
        enableBodyScroll(this.$el)
    }

    beforeDestroy(): void {
        this.close()
    }
}
