
import {FunctionalComponentOptions} from 'vue'
import {mergeData} from 'vue-functional-data-merge'

const component: FunctionalComponentOptions<{tag: string; icon: string; title: string}> = {
    name: 'ConnectionOverview',
    functional: true,
    props: {
        tag: {
            type: String,
            default: 'router-link',
        },
        icon: {
            type: String,
        },
        title: {
            type: String,
            required: true,
        },
    },
    render(createElement, {data, props: {tag, icon, title}}) {
        const children = [
            createElement('span', {staticClass: 'item-title'}, title),
            createElement('svg-sprite', {staticClass: 'chevron', props: {icon: 'chevron-right'}}),
        ]

        if (icon) {
            children.unshift(createElement('svg-sprite', {staticClass: 'item-icon', props: {icon}}))
        }

        return createElement(
            tag,
            mergeData(data, {
                staticClass: 'menu-item',
            }),
            children,
        )
    },
}
export default component
