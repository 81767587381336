import {loadJs} from '@common/lib'
import {ENV} from '@/env'

let initialized = false
async function initApp() {
    if (initialized || !ENV.IS_APP) {
        return
    }
    initialized = true

    // init cordova
    if (!window.cordova) {
        await loadJs('/cordova/cordova.js')
    }
}

if (ENV.IS_APP) {
    initApp()
}
