
import type {FunctionalComponentOptions} from 'vue'

type RouteProps = {
    href: string
    isActive: boolean
    navigate: (event: MouseEvent) => void
}

export type BottomBarItem = {
    toName: string
    icon: string
    title: string
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {}

const component: FunctionalComponentOptions<{
    items: BottomBarItem[]
}> = {
    name: 'BottomBar',
    functional: true,
    props: {
        items: {
            type: Array,
            required: true,
        },
    },
    render(createElement, {listeners, props: {items}}) {
        // eslint-disable-next-line @typescript-eslint/ban-types
        const emitItemClick = (listeners.extraClick as Function | undefined) || noop

        function renderActualLink({href, isActive, navigate}: RouteProps, icon: string, title: string) {
            function handleClick(event: MouseEvent) {
                if (isActive) {
                    emitItemClick()
                }
                navigate(event)
            }

            return createElement(
                'a',
                {
                    staticClass: 'bottom-bar-item',
                    class: {'active-link': isActive},
                    attrs: {href},
                    on: {click: handleClick},
                },
                [createElement('svg-sprite', {props: {icon, title}})],
            )
        }

        const childElements = items.map(({toName, icon, title}) => {
            return createElement('router-link', {
                key: `bottom-bar-item-${toName}`,
                props: {to: {name: toName}},
                scopedSlots: {
                    default: (props) => renderActualLink(props, icon, title),
                },
            })
        })

        return createElement('nav', {staticClass: 'bottom-bar'}, childElements)
    },
}
export default component
