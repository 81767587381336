export function compareASC<T extends string | number>(a: T, b: T): -1 | 0 | 1 {
    if (a < b) {
        return -1
    }
    if (a > b) {
        return 1
    }
    return 0
}

export function compareDESC<T extends string | number>(a: T, b: T): -1 | 0 | 1 {
    if (a < b) {
        return 1
    }
    if (a > b) {
        return -1
    }
    return 0
}
