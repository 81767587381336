import {createTypedStorage} from '@common/lib'
import type {StationPanelSettings} from '@/store/state'

export type StorageTypes = {
    deviceId: string
    token: string
    appVersion: string
    appBuild: string
    homeStation: Dbi.Station
    detailedStation: Dbi.Station
    stationPanelSettings: StationPanelSettings
}

export const typedStorage = createTypedStorage<StorageTypes>(localStorage, 'dbi_')
