





















import {Component, Vue} from 'vue-property-decorator'

import DbiLayer from '@/components/layout/DbiLayer.vue'
import DbiLayerTopBar from '@/components/layout/DbiLayerTopBar.vue'
import DbiMenuItem from '@/components/menu/DbiMenuItem.vue'
import DbiConfig from '@/components/menu/DbiConfig.vue'

const MENU_ITEMS = [
    {
        toName: 'connection-search',
        icon: 'connection-search',
        title: 'Verbindungssuche',
    },
    {toName: 'station-panel', icon: 'timetable', title: 'Bahnhofstafel'},
    {toName: 'station-details', icon: 'station', title: 'Bahnhofsinformationen'},
    {toName: 'station-map', icon: 'destination', title: 'Bahnhofsplan'},
]

@Component({
    name: 'DbiMenu',
    components: {
        DbiLayer,
        DbiLayerTopBar,
        DbiMenuItem,
        DbiConfig,
    },
})
export default class DbiMenu extends Vue {
    menuItems = MENU_ITEMS

    buttons = {
        left: {
            icon: 'menu',
            title: 'schließen',
            onClick: this.close.bind(this),
        },
        right: {
            icon: 'person',
            title: 'Konfiguration',
            onClick: this.openConfig.bind(this),
        },
    }

    get menuOpen(): boolean {
        return this.$dbiStore.state.menuOpen
    }

    openConfig(): void {
        this.$dbiStore.mutations.configOpen(true)
    }

    close(): void {
        this.$dbiStore.mutations.menuOpen(false)
    }
}
