























import {Component, Vue} from 'vue-property-decorator'

import {actionLog} from '@common/lib/logger'

@Component({
    name: 'Log',
})
export default class Log extends Vue {
    actionLog = actionLog
}
